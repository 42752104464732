@import url('https://unpkg.com/primer/build/build.css');
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700');
@import 'highlight-syntax';
@import 'highlight-syntax-dark';

body {
  font-family: 'Rubik', sans-serif;
  background: #eeedde;
}

img {
  max-width: 100%; 
  height: auto;
  border-radius: 8px;
}

a {
  overflow-wrap: anywhere;
}

iframe {
  max-width: 100%;
}

// Code block
div.highlight {
  margin-top: 16px;
  margin-bottom: 12px;
}

code,
pre {
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
}

pre {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 1em;
  margin-bottom: 10px;
  overflow-x: auto;
  vertical-align: text-bottom;
}

code {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: .2em .4em;
  font-size: 80%;
  word-break: break-word;
}

pre code {
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 80%;
}

// Indent and style lists
ol, ul {
  margin-left: 30px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  margin-bottom: 5px;
}

ul.list-style-none {
  padding-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; 
}

// If a user adds a custom font, this component will stop it from bleeding into GitHub components:
.github-component {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.article {
  h1, h2, h3, h4 {
    margin-bottom: 16px;
  }

  blockquote {
    color: #6a737d;
    border-left: 2px solid #959da5;
    padding-left: 16px;
  }
}

// menu border color
.UnderlineNav-item.selected {
    border-bottom-color: #000000;
}

//tables
table {
  margin: 5px auto;
  padding: 2px;
  border: 2px solid lightgrey;
  width: 100%;
}

th,td {
  border: 2px solid grey;
}

tr:nth-child(even) {
  background-color: lightgrey;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #353935;
    color: #f9f6ee;
  }

  a {
    color: #8cd2d5;
  }

  .text-gray {
    color: #fffdd0 !important;
  }

  .UnderlineNav-item {
    color: #ffffff;
  }

  .UnderlineNav-item.selected {
    color: #ffffff;
    border-bottom-color: #ffffff;
  }

  .bg-white {
    background: #36454f !important;
  }

  .matheqn {
    filter: invert(100%);
  }

  tr:nth-child(even) {
    background-color: darkgrey;
  }

  code {
    background: #191970;
  }

  .pagination {
    span, a {
      color: #8cd2d5;
      background: #353935;
    }

    a:hover, a:focus, span:hover, span:focus {
      background: #36454f;
    }
  }
  .article {
    blockquote {
      color: #bfbfbf;
    }
  }
}
